html, body {
  box-sizing: border-box;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: auto;
}

*, *:before, *:after {
  /* box-sizing: inherit; */
}

.TTLogo {
  height: 40px;
  margin-right: 10px;
}

.Toolbar-Buttons {
  display: flex;
}

.ListItem {
  marginBottom: 50px;
}

.ToolbarIcon {
  height: 16px;
  margin: 0 3px 0 3px;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
}

.IconGrid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto;
}

.Icon {
  height: 65px;
  width: 65px;
  box-sizing: border-box;
  margin: 15px;
}

.ImgCutout {
  transition-duration: 0.6s;
}


/* Smartphones (portrait) ----------- */
@media only screen
and (max-width : 411px) {
  .SiteNav {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .Toolbar-Buttons {
    visibility: collapse;
    height: 0px;
  }

  .TTLogo {
    height: 75px;
  }

  .SplashLogo {
    width: 80%;
  }

  .DesktopDivider {
    height: 40px;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen
and (min-width : 412px) {
  .SiteNav {
    display: flex;
    justify-content: space-between;
  }

  .SplashLogo {
    width: 70%;
  }

  .DesktopDivider {
    height: 30px;
  }
}

/* Desktops and laptops ----------- */
@media only screen
and (min-width : 900px) {
  .SiteNav {
    display: flex;
    justify-content: space-between;
  }

  .SplashLogo {
    width: 600px;
  }

  .DesktopDivider {
    height: 10px;
  }
}

.h3custom {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-family: 'Courier New', Courier, monospace;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-size: 1.1em;
  font-weight: bold;
}

.layoutHoriz {
  display: flex;
  justify-content: space-between;
}

.listOuterContainer {
  width: 150px;
  display: flex;  
  justify-content: center;
}

.listOuterContainerL {
  width: 150px;
  display: flex;
  justify-content: baseline;
}

.listInnerContainer {  
  /* background-color: lightblue; */
  justify-content: center;
}
